<script setup lang="ts"></script>

<template>
  <NovaLayoutWrapper>
    <NovaLayoutMain>
      <template #center>
        <slot />
      </template>
    </NovaLayoutMain>
  </NovaLayoutWrapper>
</template>
